import Container from '@mui/material/Container';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { PropsWithChildren } from 'react';
import Footer from '../Footer';
import Header from '../Header';

const BasicLayout = ({ children }: PropsWithChildren) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container maxWidth="xl" sx={{ p: 0 }}>
        <Header />
        {children}
        <Footer />
      </Container>
    </LocalizationProvider>
  );
};

export default BasicLayout;
