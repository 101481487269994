import React from 'react';
import Container from '@mui/material/Container';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Header from './components/Header';
import Footer from './components/Footer';
import ReviewRequestCard from './components/ReviewRequestCard';

function ReviewRequest() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container sx={{ px: 0 }} className="lender-details">
        <Header />
        <Container className="body" sx={{ pt: 3 }}>
          <ReviewRequestCard />
        </Container>
        <Footer />
      </Container>
    </LocalizationProvider>
  );
}
export default ReviewRequest;
