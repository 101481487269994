import React, { useEffect, useState } from 'react';
import '../styles/ConfirmationPageCard.scss';
import '../styles/Global.scss';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useSegment from '../hooks/useSegment';
import successIcon from '../images/icons/success-large.svg';
import checkIcon from '../images/icons/check-icon.svg';
import Container from '@mui/material/Container';
import '@aaa-ncnu-ie/fonts/cabin/index.css';
import { getCookie } from './helpers/cookies';
import { useNavigate } from 'react-router-dom';

function ConfirmationPage() {
  const [isUpdateMortgagee, setIsUpdateMortgagee] = useState(false);
  const { track } = useSegment();
  const navigate = useNavigate();
  useEffect(() => {
    const contactInformation = sessionStorage.getItem('ContactInfo');
    if (!getCookie('token')) {
      navigate('/');
    }
    setIsUpdateMortgagee(
      JSON.parse(contactInformation || '{}').isUpdateMortgagee || false
    );
  }, []);

  const handleClick = () => {
    track('Additional Submission Requested', {
      event_type: 'Link Accessed',
    });
    sessionStorage.clear();
  };
  return (
    <div className="DocumentDetailsCard">
      <div className="PolicyDetailsCard-title">Request submitted</div>
      <div className="Success-alert">
        <img src={checkIcon} className="alert-icon" alt="" />
        <b>Your request has been submitted</b>
      </div>
      <div className="PolicyDetailsCard-main">
        <Container className="PolicyDetailsCard-details">
          <Box className="PolicyDetailsCard-box-details">
            <img src={successIcon} className="Success-image" alt="" />
            <div className="success-message">
              {isUpdateMortgagee
                ? 'We are working on your request and will send you the updated documents through your preferred method within two business days.'
                : 'We are working on your request and will send you the documents through your preferred method soon.'}
            </div>
            <div className="buttonGroup">
              <Button variant="contained" href="/" onClick={handleClick}>
                Submit another request
              </Button>
            </div>
          </Box>
        </Container>
      </div>
    </div>
  );
}
export default ConfirmationPage;
