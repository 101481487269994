import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActionCreators } from '../api/actions';
import useRequest from '../api/makeRequest';
import {
  PolicyResponseDetails,
  Mortgagee as PoliciesMortgagee,
  PropertyDetails,
} from '../api/schema/apiPolicies';
import {
  Mortgagee as DocMortgagee,
  DocumentsRequestNameEmail,
} from '../api/schema/apiDocuments';
import useSegment from '../hooks/useSegment';
import homeIcon from '../images/icons/home-icon.svg';
import documentIcon from '../images/icons/action-documents.svg';
import userIcon from '../images/icons/user-icon.svg';
import mortgageeIcon from '../images/icons/mortgagee-icon.svg';
import viewDocs from '../images/icons/view-docs-icon.svg';
import changeDocs from '../images/icons/change-docs-icon.svg';
import addMortgageeIcon from '../images/icons/add-mort.svg';
import '../styles/./RealTimePolicyDetailsCard.scss';
import '../styles/./Global.scss';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import '@aaa-ncnu-ie/fonts/cabin/index.css';
import {
  Alert,
  AlertTitle,
  Collapse,
  DialogActions,
  DialogContent,
  DialogContentText,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Icon from '@mui/material/Icon';
import policyDetails from '../helpers/policyDetails';
import { InsuredContext } from './contexts/InsuredContext';
import { LoadingContext } from './contexts/LoadingContext';
import { getCookie, setCookie } from './helpers/cookies';
import { getYearFromStringDateDDmmYYYY } from './helpers/utils';
import RealTimeMortChangeModal from './modal/RealTimeMortChangeModal';
import Modal from './common/Modal';
import { ReactComponent as IconInfo } from '../images/icons/info.svg';
import { ReactComponent as IconInfoYellow } from '../images/icons/info-yellow.svg';
import { RealTimeResponseContext } from './contexts/RealTimeResponseContext';

function RealTimePolicyDetails() {
  const [insuredContext, setInsuredContext] = useContext(InsuredContext);
  const [lienList, setLienList] = useState([] as Array<DocMortgagee>);
  const [currentLien, setCurrentLien] = useState({} as DocMortgagee);
  const [showEdit, setShowEdit] = useState(true);
  const [, setIsGuestUser] = useState(false);
  const [isRequestDocuments, setRequestDocuments] = useState(false);
  const [guestUserClass, setGuestUserClass] = useState('');
  const [showButtons, setShowButtons] = useState(false);
  const [showDocs, setShowDocs] = useState(true);
  const [lienModalOpened, setLienModalOpened] = useState(false);
  const [deleteConfirmationModalOpened, setDeleteConfirmationModalOpened] =
    useState(false);
  const [, updateLoadingContext] = useContext(LoadingContext);
  const [realtimeUpdateContext, updateRealTimeContext] = useContext(
    RealTimeResponseContext
  );
  const isRealTimeEnabled = process.env.REACT_APP_ENABLE_MORTGAGEE === 'true';

  const [, setShowRenewalCheckbox] = useState(false);
  const [showInfoMessageNoMortgagee, setShowInfoMessageNoMortgagee] =
    useState(false);
  const [showDocumentDownloads, setShowDocumentDownloads] = useState(false);
  const makeRequest = useRequest();
  const navigate = useNavigate();
  const { track } = useSegment();
  const [segmentUserDetails, setSegmentUserDetails] = useState({});

  const {
    actionCreators: { policy },
  } = useActionCreators();
  const [policyResponse, setPolicyResponse] = useState<
    PolicyResponseDetails | undefined
  >();
  const checkForRenewalAndBuildLienList = (
    policyResponseDetails: PolicyResponseDetails | undefined
  ) => {
    if (policyResponseDetails === undefined) return;
    const {
      propertyDetails: { mortgagee },
      policySummary: { expirationDate },
    } = policyResponseDetails;
    const dateDifference = dayjs(expirationDate).diff(dayjs(), 'day');
    setShowRenewalCheckbox(dateDifference < 31 && dateDifference > -1);

    setLienList(
      mortgagee.map((mortgagee: PoliciesMortgagee) =>
        policyDetails.mapToLien(mortgagee)
      )
    );

    setShowInfoMessageNoMortgagee(!mortgagee?.length);
  };

  const segmentTrackLienToBeDeleted = (lienPosition: number) => {
    const lienToBeDeleted = lienList.find(
      (l) => l.lienPosition === lienPosition
    );
    track('Mortgagee Information Deleted', {
      event_type: 'Link Accessed',
      mortgagee_name: lienToBeDeleted?.legalMortgageeName,
      address: lienToBeDeleted?.mortgageeAddress1,
      city: lienToBeDeleted?.mortgageeCity,
      state: lienToBeDeleted?.mortgageeState,
      zip_code: lienToBeDeleted?.mortgageePostalCode,
      loan_number: lienToBeDeleted?.loanNumber,
    });
  };

  const onLienDelete = (lienPosition: number) => {
    const {
      propertyDetails: { mortgagee },
    } = policyResponse as PolicyResponseDetails;
    const originalLienSize = mortgagee.length || 0;
    let filteredLienList: Array<DocMortgagee>;

    segmentTrackLienToBeDeleted(lienPosition);

    if (lienPosition > originalLienSize) {
      filteredLienList = policyDetails.deleteRecentlyAddedLien(
        lienList,
        lienPosition
      );
    } else {
      if (lienList.length > originalLienSize) {
        filteredLienList =
          policyDetails.replaceExistingLienWithNewlyAddedOneAndMarkItAsEdit(
            lienList,
            lienPosition
          );
      } else {
        filteredLienList = policyDetails.markExistingLienToBeDeleted(
          mortgagee,
          lienList,
          lienPosition
        );
      }
    }

    setLienList(
      policyDetails.repositionLiensInAscendingOrder(filteredLienList)
    );
    setLienModalOpened(false);
    setDeleteConfirmationModalOpened(false);
  };

  const openEditLienModal = (lien: any) => {
    setCurrentLien(lien);
    setLienModalOpened(true);
  };

  const openDeleteConfirmationModal = (lien: any) => {
    setCurrentLien(lien);
    setDeleteConfirmationModalOpened(true);
  };

  const handleContinue = () => {
    const docsRequest: DocumentsRequestNameEmail =
      policyDetails.getInsuredContextNameEmail(
        insuredContext,
        insuredContext.lenderEmail
      );

    sessionStorage.setItem('Request Documents', JSON.stringify(docsRequest));
  };

  const openAddLienModal = () => {
    setCurrentLien({} as DocMortgagee);
    setLienModalOpened(true);
  };

  const segmentTrackAbsenceOfMortgagees = (
    propertyDetails: PropertyDetails
  ) => {
    if (
      !propertyDetails?.mortgagee ||
      propertyDetails?.mortgagee.length === 0
    ) {
      track('No Mortgagee Information Found', {
        ...segmentUserDetails,
        ...{
          event_type: 'Messaged',
        },
      });
    }
  };

  const getPolicy = () => {
    setSegmentUserDetails({
      insured_last_name: insuredContext.insuredLastName,
      zip_code: insuredContext.propertyZipCode,
      event_type: 'Automated System Process',
      insured_dob: getYearFromStringDateDDmmYYYY(
        insuredContext?.insuredDateOfBirth
      ),
    });

    makeRequest(() =>
      policy()
        .then((response: any) => {
          setPolicyResponse(response.policyResponseDetails);

          segmentTrackAbsenceOfMortgagees(
            response?.policyResponseDetails?.propertyDetails
          );

          let isUnvalidatedUser = false;

          if (!response.policyResponseDetails?.policySummary?.policyStatus) {
            isUnvalidatedUser = true;
            setIsGuestUser(isUnvalidatedUser);
            setGuestUserClass('hidden');
            setShowEdit(true);
            setShowDocs(true);
            setShowButtons(false);
            track('Unvalidated User Admitted', {
              event_type: 'Automated System Process',
            });
          }

          if (
            response.policyResponseDetails?.documents &&
            response.policyResponseDetails.documents.length > 0
          ) {
            setShowDocumentDownloads(true);
          }
          checkForRenewalAndBuildLienList(response.policyResponseDetails);
          updateLoadingContext({ value: false });
          updateRealTimeContext({
            zendeskRequest: false,
            realTimeRequest: false,
          });
          setCookie('token', response.accessToken);
        })
        .catch(() => {
          updateLoadingContext({ value: false });
          setInsuredContext({ incorrectPolicyInformation: true });
          navigate('/');
        })
    );
  };

  const policyIsCancelledOrInactive = [
    'Canceled',
    'Cancelled',
    'Inactive',
  ].includes(policyResponse?.policySummary?.policyStatus || '');

  useEffect(() => {
    const requestDocumentsResponse =
      sessionStorage.getItem('Request Documents Success') === 'false' || false;
    setRequestDocuments(requestDocumentsResponse);
    const insured = JSON.parse(sessionStorage.getItem('PolicyLookup') || '{}');
    if (getCookie('token') && Object.keys(insured).length > 0) {
      setInsuredContext(insured);
      updateLoadingContext({
        value: true,
        message: 'We’re gathering details of the policy you’ve requested.',
      });
      getPolicy();
    } else {
      navigate('/');
    }
  }, []);

  const MortCard = ({ lien }: any) => {
    const lienText: string = policyDetails.getLienNumberText(lien.lienPosition);
    const MortName = lien?.legalMortgageeName;
    const MortName2 = '';
    const MortAddress = lien?.mortgageeAddress1;
    const MortCity = lien?.mortgageeCity;
    const MortState = lien?.mortgageeState;
    const MortZip = lien?.mortgageePostalCode;
    const MortLoan = lien?.loanNumber
      ? `Loan number:\n${lien?.loanNumber}`
      : '';

    return (
      <div className="mort-card">
        <div className="lien">
          <span>{lienText}</span>
        </div>
        <div className="lien-holder">{MortName}</div>
        <div className="lien-address" style={{ whiteSpace: 'pre-line' }}>
          {MortName2}
          <br />
          {MortAddress}
          <br />
          {MortCity}, {MortState} {MortZip}
          <br />
          <br />
          {MortLoan}
        </div>
        {showEdit && (
          <div className="buttonContainer">
            <EditButton lien={lien} />
            {lien.lienPosition > 1 && <DeleteButton lien={lien} />}
          </div>
        )}
      </div>
    );
  };

  const addMortIcon = (
    <Icon>
      <img alt="Add Mortgagee" src={addMortgageeIcon} />
    </Icon>
  );

  const DeleteConfirmationModal = ({ open, onClose }: any) => (
    <Modal
      fullWidth={false}
      maxWidth={'md'}
      title="Delete mortgagee?"
      open={open}
      className="mort-modal deleteConfirmationModal"
      modalName="mort-modal-delete"
      onClose={onClose}
    >
      <DialogContent className="PolicyDetailsCard-delete-mortgagee-message">
        <DialogContentText>
          By clicking continue, this mortgagee will be removed from this policy.
          <span />
          You will need to click “Add Mortgagee” and input the mortgagee
          information if you made a mistake.
        </DialogContentText>
      </DialogContent>
      <DialogActions className="PolicyDetailsCard-delete-mortgagee-buttons">
        <Box justifyContent="center" style={{ width: '95%' }}>
          <Button
            style={{ width: '95%', marginTop: '30px' }}
            onClick={() =>
              currentLien.lienPosition && onLienDelete(currentLien.lienPosition)
            }
          >
            Continue
          </Button>
        </Box>
      </DialogActions>
    </Modal>
  );

  const handleLenderButtonClick = (isEdit: boolean) => {
    let selection = 'view current policy';
    if (isEdit) {
      selection = 'make mortgagee changes';
      track('', {});
      setShowEdit(true);
      // setShowDocumentDelivery(true);
      setShowInfoMessageNoMortgagee(false);
    }
    track('Request Type Selected', {
      selection,
      event_type: 'Option Selected',
    });
    setShowDocs(true);
    setShowButtons(false);
  };

  const LenderButton = (props: any) => (
    <Button
      onClick={() => handleLenderButtonClick(props.edit === 'true')}
      className="lien-action"
      variant="outlined"
    >
      {props.children}
    </Button>
  );

  const mapDocumentCodeToLabel = (code: string) => {
    switch (code) {
      case 'EOI':
        return 'Evidence of insurance';
      case 'REPL_COST':
        return 'Replacement Cost Estimator';
      case 'INVOICE_PROP':
        return 'Billing Invoice';
      default:
        return 'unknown';
    }
  };

  const viewFile = async (content: string, document: string) => {
    track('Document Selected', {
      event_type: 'Link Accessed',
      document,
    });
    const binary = atob(content?.replace(/\s/g, ''));
    const len = binary?.length || 0;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary?.charCodeAt(i);
    }
    const file = new Blob([view], { type: 'application/pdf' });
    const fileUrl = window.URL.createObjectURL(file);
    window.open(fileUrl, '_blank');
  };

  const DocumentRequest = () => (
    <Stack id="document-section" sx={{ width: 1 }}>
      {showEdit && (
        <Stack
          flexDirection="column"
          alignItems="flex-start"
          className="PolicyDetailsCard-details-section"
        >
          <Stack
            flexDirection="row"
            alignItems="flex-start"
            sx={{ gap: '11px', mb: 2, mr: 0 }}
          >
            <img
              src={documentIcon}
              className="PolicyDetailsCard-policy-image"
              alt=""
            />
            <Stack sx={{ mr: 2 }}>
              <Typography sx={{ fontSize: { tablet: 24 }, fontWeight: 700 }}>
                Policy Documents
              </Typography>
              <Typography sx={{ fontSize: { tablet: 18 } }}>
                Documents open in a new tab/window. Allow pop-ups in your
                browser settings.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
      {showDocumentDownloads && (
        <TableContainer
          sx={{ ml: 7, boxShadow: 'none', width: '95%' }}
          component={Paper}
        >
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            <TableHead sx={{ bgcolor: '#F7F8FC' }}>
              <TableRow>
                <TableCell align="left" sx={{ width: '50%' }}>
                  Document
                </TableCell>
                <TableCell align="left">Process Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {policyResponse?.documents &&
                policyResponse.documents?.map((document, index) => {
                  const category = mapDocumentCodeToLabel(document.category);
                  if (category === 'unknown') return '';
                  return (
                    <TableRow key={`${document.content}-${index}]`}>
                      <TableCell align="left" sx={{ width: '50%' }}>
                        <Link
                          href="#document-section"
                          onClick={() => viewFile(document.content, category)}
                          sx={{ color: '#115DC4' }}
                        >
                          {category}
                        </Link>
                        <Box
                          component="span"
                          sx={{
                            fontSize: '14px',
                            borderSpacing: 2,
                            border: 0,
                            lineHeight: '16px',
                            fontWeight: 500,
                            font: 'Cabin',
                            p: '8px 8px 4px',
                            bgcolor: '#f2f6fc',
                            borderRadius: 1,
                            ml: 1,
                          }}
                        >
                          New
                        </Box>
                      </TableCell>
                      <TableCell align="left">02/03/2025</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Stack flexDirection="row" justifyContent="flex-end" sx={{ mt: 5 }}>
        <Button
          variant="contained"
          onClick={() => {
            handleContinue();
            navigate('/realTimeDocuments');
          }}
        >
          Request Documents
        </Button>
      </Stack>
    </Stack>
  );

  const EditButton = (props: any) => (
    <Button variant="text" onClick={() => openEditLienModal(props.lien)}>
      Make changes
    </Button>
  );

  const DeleteButton = (props: any) => (
    <Button
      variant="text"
      onClick={() => openDeleteConfirmationModal(props.lien)}
    >
      Delete
    </Button>
  );

  const AddMortgagee = () => (
    <div className="mort-card mort-card-add">
      <Button startIcon={addMortIcon} onClick={openAddLienModal} variant="text">
        Add mortgagee
      </Button>
    </div>
  );

  const LenderButtons = () => (
    <>
      <div className="lender-buttons-title">
        Please select the type of request you would like to make
      </div>
      <div className="lien-action-group">
        {!showInfoMessageNoMortgagee && (
          <LenderButton edit="false">
            <div className="lien-action-text">
              <div className="button-title">
                View Current Policy Information and Documents
              </div>
              <div className="button-text">
                Requests made here will be processed in near real-time.
              </div>
            </div>
            <img src={viewDocs} className="view-docs" alt="" />
          </LenderButton>
        )}
        <LenderButton edit="true">
          <div className="lien-action-text">
            <div className="button-title">
              Make mortgagee changes and request documents
            </div>
            <div className="button-text">
              Requests made here will be processed within two business days.
            </div>
          </div>
          <img src={changeDocs} className="view-docs" alt="" />
        </LenderButton>
      </div>
    </>
  );

  const MortCards = () => {
    return (
      <Stack flexDirection="row" sx={{ flexWrap: 'wrap', gap: '24px', mt: 3 }}>
        {lienList.map((mortgagee, i) => {
          return mortgagee.requestType !== 'delete' ? (
            <MortCard key={i} lien={mortgagee} />
          ) : (
            ''
          );
        })}
        {showEdit ? <AddMortgagee /> : null}
      </Stack>
    );
  };

  return (
    <div className="PolicyDetailsCard">
      {isRealTimeEnabled && (
        <>
          <Container>
            <Collapse in={realtimeUpdateContext.zendeskRequest} unmountOnExit>
              <Alert
                severity="warning"
                className="PolicyLookupCard-error-message"
                onClose={() => {
                  updateRealTimeContext({
                    zendeskRequest: false,
                  });
                }}
              >
                <AlertTitle>
                  Your mortgagee changes have been requested.
                </AlertTitle>
                Your changes will be processed and documents will be sent within
                two business days.
              </Alert>
            </Collapse>
          </Container>
          <Container>
            <Collapse in={isRequestDocuments} unmountOnExit>
              <Alert
                severity="warning"
                className="PolicyLookupCard-error-message"
                onClose={() => {
                  sessionStorage.removeItem('Request Documents Success');
                  setRequestDocuments(false);
                }}
              >
                <AlertTitle>Your request has been submitted.</AlertTitle>
                We are processing your request. The documents will be sent to
                the method selected.
              </Alert>
            </Collapse>
          </Container>
          <Container>
            <Collapse in={realtimeUpdateContext.realTimeRequest} unmountOnExit>
              <Alert
                severity="success"
                className="PolicyLookupCard-error-message"
                onClose={() => {
                  updateRealTimeContext({
                    realTimeRequest: false,
                  });
                }}
              >
                <AlertTitle>
                  Your mortgagee changes have been processed.
                </AlertTitle>
                View or request your updated documents below.
              </Alert>
            </Collapse>
          </Container>
          <Container>
            <Collapse in={insuredContext.errorRetrievingPolicy} unmountOnExit>
              <Alert
                severity="error"
                className="PolicyLookupCard-error-message"
                onClose={() => {
                  updateRealTimeContext({
                    realTimeRequest: false,
                  });
                }}
              >
                <AlertTitle>
                  We&apos;re unable to update this mortgagee.
                </AlertTitle>
                Please check your details and try again.
              </Alert>
            </Collapse>
          </Container>
          <div className="PolicyDetailsCard-title">Property policy</div>
          <div className="PolicyDetailsCard-subtitle">
            We found the policy below based on the information you entered.
            {
              <>
                {' '}
                Please select the type of document and delivery method to
                continue your request.
                <br />
                If you would like to change mortgagee information, please click
                “Make changes” or “Add mortgagee”.
              </>
            }
          </div>
          <div className="PolicyDetailsCard-main">
            <Container sx={{ p: 0 }} className="PolicyDetailsCard-header">
              <Box sx={{ px: 5, py: 3 }} className="PolicyDetailsCard-box">
                <img
                  src={homeIcon}
                  className="PolicyDetailsCard-policy-image"
                  alt=""
                />
                <div className="PolicyDetailsCard-header-text">
                  <div className="PolicyDetailsCard-policy-number">
                    {policyResponse?.policySummary?.policyNumber}
                    <span
                      className={`${policyResponse?.policySummary?.policyStatus?.toLowerCase()} ${guestUserClass}`}
                    >
                      {policyResponse?.policySummary?.policyStatus}
                    </span>
                  </div>
                  <div
                    className={`PolicyDetailsCard-policy-period ${guestUserClass}`}
                  >
                    {
                      policyResponse?.policySummary?.policyDetails
                        ?.insuredAddress.addressLine1
                    }
                    ,{' '}
                    {
                      policyResponse?.policySummary?.policyDetails
                        ?.insuredAddress.city
                    }
                    ,{' '}
                    {
                      policyResponse?.policySummary?.policyDetails
                        ?.insuredAddress.stateProvCd
                    }{' '}
                    {
                      policyResponse?.policySummary?.policyDetails
                        ?.insuredAddress.postalCode
                    }
                    <br />
                    Policy period {
                      policyResponse?.policySummary?.effectiveDate
                    }{' '}
                    - {policyResponse?.policySummary?.expirationDate}
                  </div>
                </div>
              </Box>
            </Container>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                flexWrap: 'wrap',
                backgroundColor: '#ffffff',
                borderRadius: '0 0 8px 8px',
                pt: '1px',
              }}
            >
              {policyIsCancelledOrInactive && (
                <Alert
                  severity="info"
                  variant="standard"
                  icon={<IconInfo />}
                  className="PolicyDetailsCard-details-info-message"
                >
                  This policy is{' '}
                  {policyResponse?.policySummary?.policyStatus.toLowerCase()}.
                  No changes can be made at this time.
                </Alert>
              )}
              <Stack
                alignItems="flex-start"
                justifyContent="space-between"
                sx={{
                  p: '40px 80px 40px 40px',
                  lineHeight: '24px',
                  fontSize: 18,
                  width: 1,
                }}
                className="PolicyDetailsCard-box-details"
              >
                <Stack
                  flexDirection="row"
                  alignItems="flex-start"
                  sx={{ gap: '11px', mb: 5 }}
                  className={`PolicyDetailsCard-details-section ${guestUserClass}`}
                >
                  <img
                    src={userIcon}
                    className="PolicyDetailsCard-policy-image"
                    alt=""
                  />
                  <Stack>
                    <Typography
                      sx={{ fontSize: { tablet: 24 }, fontWeight: 700 }}
                    >
                      Named insured(s)
                    </Typography>
                    <Typography sx={{ fontSize: { tablet: 18 } }}>
                      {policyDetails.getNameInsured(
                        policyResponse?.policySummary?.insureds
                      )}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  flexDirection="row"
                  alignItems="flex-start"
                  sx={{
                    gap: '11px',
                  }}
                  className="PolicyDetailsCard-details-section-vert"
                >
                  <img
                    src={mortgageeIcon}
                    className="PolicyDetailsCard-policy-image"
                    alt=""
                  />
                  <Stack sx={{ mb: 5 }}>
                    <Typography
                      sx={{ fontSize: 3, fontWeight: 700 }}
                      className="title"
                    >
                      Mortgagee Information
                    </Typography>
                    {showInfoMessageNoMortgagee && (
                      <Alert
                        severity="warning"
                        variant="outlined"
                        icon={<IconInfoYellow />}
                        className="PolicyDetailsCard-details-info-message-no-mortgagee"
                      >
                        No mortgagee information found.
                        {!policyIsCancelledOrInactive && (
                          <Button
                            variant="text"
                            onClick={() => {
                              handleLenderButtonClick(true);
                              openAddLienModal();
                            }}
                          >
                            {' '}
                            Add mortgagee information now.
                          </Button>
                        )}
                      </Alert>
                    )}
                    <MortCards />
                  </Stack>
                </Stack>
                {policyIsCancelledOrInactive ? (
                  <div className="PolicyDetailsCard-start-over">
                    <Button
                      variant="contained"
                      onClick={() => {
                        navigate('/');
                      }}
                    >
                      Start Over
                    </Button>
                  </div>
                ) : (
                  showButtons && <LenderButtons />
                )}
                {showDocs ? <DocumentRequest /> : null}
                <RealTimeMortChangeModal
                  open={lienModalOpened}
                  currentLien={currentLien}
                  policyInsured={policyDetails.getNameInsured(
                    policyResponse?.policySummary?.insureds
                  )}
                  lienList={lienList}
                  onClose={() => setLienModalOpened(false)}
                  originalLienSize={
                    policyResponse?.propertyDetails?.mortgagee.length || 0
                  }
                  setLienList={setLienList}
                />
                <DeleteConfirmationModal
                  open={deleteConfirmationModalOpened}
                  onClose={() => setDeleteConfirmationModalOpened(false)}
                />
              </Stack>
            </Stack>
          </div>
        </>
      )}
    </div>
  );
}
export default RealTimePolicyDetails;
