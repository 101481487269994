import { FormControl } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { defaultLien, Mortgagee } from '../../api/schema/apiDocuments';
import policyDetails from '../../helpers/policyDetails';
import useSegment from '../../hooks/useSegment';
import Modal from '../common/Modal';
import UsStatesSelector from '../UsStatesSelector';
import '../../styles/modal/MortChangeModal.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import mortChangeModalSchema from '../../yup-schemas/MortChangeModal';
import { PatternFormat } from 'react-number-format';

const MortChangeModal = (props: {
  currentLien: Mortgagee;
  open: boolean;
  onClose: () => void;
  originalLienSize: number;
  lienList: Array<Mortgagee>;
  setLienList: (lienList: Array<Mortgagee>) => void;
}) => {
  const {
    currentLien,
    open,
    onClose,
    originalLienSize,
    lienList,
    setLienList,
  } = props;
  const [lien, setLien] = useState(defaultLien as Mortgagee);
  const { track } = useSegment();
  const isUpdateLienMode = Object.keys(currentLien).length > 0;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(mortChangeModalSchema),
  });

  const closeModal = () => {
    onClose();
    reset();
  };

  useEffect(() => {
    if (open && isUpdateLienMode) {
      setLien(currentLien);
      setValue('legalMortgageeName', currentLien.legalMortgageeName);
      setValue('mortgageeAddress1', currentLien.mortgageeAddress1);
      setValue('mortgageeCity', currentLien.mortgageeCity);
      setValue('mortgageeState', currentLien.mortgageeState);
      setValue('mortgageePostalCode', currentLien.mortgageePostalCode);
      setValue('loanNumber', currentLien.loanNumber);
    } else {
      setLien(defaultLien);
    }
  }, [open]);

  const saveEditedLien = () => {
    const eventType = 'User Information Entered';
    if (isUpdateLienMode) {
      track('Mortgagee Details Changed', {
        eventType,
      });
    } else {
      track('Mortgagee Information Added', {
        eventType,
        mortgagee_name: lien.legalMortgageeName,
        address: lien.mortgageeAddress1,
        city: lien.mortgageeCity,
        state: lien.mortgageeState,
        zip_code: lien.mortgageePostalCode,
        loan_number: lien.loanNumber,
      });
    }
    const nextLienPosition =
      lienList.filter((lien) => lien.requestType !== 'delete').length + 1;
    let liens: Array<Mortgagee> = [];

    if (
      lien.lienPosition ||
      (!lien.lienPosition && nextLienPosition <= originalLienSize)
    ) {
      //has Position Or Next Position Is Less Than Original Size
      liens = policyDetails.markExistingLienToBeEdited(
        lienList,
        lien.lienPosition ? lien.lienPosition : nextLienPosition,
        lien
      );
    }
    if (!lien.lienPosition && nextLienPosition > originalLienSize) {
      // new Lien and next position is after existing liens
      liens = policyDetails.addNewLien(lienList, lien, nextLienPosition);
    }

    setLienList(liens);
    closeModal();
  };

  const handleMortChange = ({
    name,
    value,
  }: HTMLInputElement | { name: string; value: string }) => {
    setLien({ ...lien, ...{ [name]: value } });
  };
  return (
    <Modal
      fullWidth={false}
      maxWidth={'md'}
      title={isUpdateLienMode ? 'Mortgagee update' : 'Add mortgagee'}
      open={open}
      className="mort-modal"
      modalName="mort-modal"
      onClose={onClose}
    >
      <Box className="mort-modal-background">
        <DialogContent>
          <DialogContentText className="PolicyDetailsCard-details-message">
            Please provide the updated mortgagee information.
          </DialogContentText>
          <TextField
            {...register('legalMortgageeName')}
            error={!!errors.legalMortgageeName}
            autoFocus
            margin="dense"
            id="updatedMortName"
            label="Mortgagee Name including ISAOA or ATIMA if applicable"
            type="text"
            fullWidth
            name="legalMortgageeName"
            inputProps={{ maxLength: 50 }}
            variant="filled"
            onChange={(event) => handleMortChange(event.target)}
            value={lien?.legalMortgageeName}
            helperText={`${errors.legalMortgageeName?.message || ''}`}
            required
          />
          <TextField
            {...register('mortgageeAddress1')}
            error={!!errors.mortgageeAddress1}
            margin="dense"
            id="updatedMortAddress"
            label="Address"
            type="text"
            fullWidth
            name="mortgageeAddress1"
            inputProps={{ maxLength: 50 }}
            variant="filled"
            onChange={(event) => handleMortChange(event.target)}
            value={lien?.mortgageeAddress1}
            helperText={`${errors.mortgageeAddress1?.message || ''}`}
            required
          />
          <TextField
            {...register('mortgageeCity')}
            error={!!errors.mortgageeCity}
            margin="dense"
            id="updatedMortCity"
            label="City"
            type="text"
            fullWidth
            name="mortgageeCity"
            inputProps={{ maxLength: 30 }}
            variant="filled"
            onChange={(event) => handleMortChange(event.target)}
            value={lien?.mortgageeCity}
            helperText={`${errors.mortgageeCity?.message || ''}`}
            required
          />
          <FormControl
            variant="filled"
            sx={{
              m: 1,
              minWidth: 120,
              width: '100%',
              marginLeft: 0,
              marginRight: 0,
            }}
          >
            <UsStatesSelector
              initialValue={lien?.mortgageeState}
              onChangeAutoComplete={(value = '') => {
                setValue('mortgageeState', value);
                handleMortChange({ name: 'mortgageeState', value });
              }}
              onBlurAutoComplete={() => {
                trigger('mortgageeState');
              }}
              error={!!errors.mortgageeState}
              helperText={`${errors.mortgageeState?.message || ''}`}
              required
            />
          </FormControl>
          <PatternFormat
            margin="dense"
            id="updatedMortZip"
            fullWidth
            value={lien?.mortgageePostalCode}
            name="mortgageePostalCode"
            customInput={TextField}
            format={'#####-####'}
            mask="_"
            onValueChange={({ value }) => {
              setValue('mortgageePostalCode', value);
              handleMortChange({ name: 'mortgageePostalCode', value });
            }}
            onBlur={() => {
              trigger('mortgageePostalCode');
            }}
            error={!!errors.mortgageePostalCode}
            label="Zip code"
            variant="filled"
            helperText={`${errors.mortgageePostalCode?.message || ''}`}
            required
          />

          <TextField
            {...register('loanNumber')}
            error={!!errors.loanNumber}
            margin="dense"
            id="updatedMortLoan"
            label="Loan number"
            type="text"
            fullWidth
            inputProps={{ maxLength: 30 }}
            variant="filled"
            name="loanNumber"
            onChange={(event) => handleMortChange(event.target)}
            value={lien?.loanNumber}
            helperText={`${errors.loanNumber?.message || ''}`}
            required
          />

          <DialogContentText className="PolicyDetailsCard-details-message">
            Select {isUpdateLienMode ? 'update' : 'save'} below to save the
            updated mortgagee information. Select cancel to go back to the
            policy details page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(saveEditedLien)}
            disabled={!mortChangeModalSchema.isValidSync(lien)}
          >
            {isUpdateLienMode ? 'Update' : 'Save'}
          </Button>
        </DialogActions>
      </Box>
    </Modal>
  );
};

export default MortChangeModal;
