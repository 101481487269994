import React, { useContext, useEffect, useState } from 'react';
import useSegment from '../hooks/useSegment';
import { getYearFromStringDateDDmmYYYY } from './helpers/utils';
import BasicLayout from './layouts/BasicLayout';
import { Alert, Box, Button, Container, Stack, TextField } from '@mui/material';
import styles from '../styles/OtvcCard.module.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import otvcCardSchema from '../yup-schemas/OtvcCard';
import { PatternFormat } from 'react-number-format';
import { ReactComponent as IconShield } from '../images/icons/icon-shield.svg';
import { InsuredContext } from './contexts/InsuredContext';
import { obfuscateEmail } from '../helpers/otvcCard';
import { useNavigate } from 'react-router-dom';
import { useActionCreators } from '../api/actions';
import policyDetails from '../helpers/policyDetails';
import useRequest from '../api/makeRequest';
import { LoadingContext } from './contexts/LoadingContext';
import { OtpGenerateRequest } from '../api/schema/apiOtpGenerate';
import { setCookie } from './helpers/cookies';
import { ReactComponent as IconInfo } from '../images/icons/info.svg';
import { OtpValidateRequest } from '../api/schema/apiOtpValidate';

const OtvcCard = () => {
  const [insuredContext, setInsuredContext] = useContext(InsuredContext);
  const [, updateLoadingContext] = useContext(LoadingContext);
  const [showResendInfo, setShowResendInfo] = useState(false);
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [numberOfAttempts, setNumberOfAttempts] = useState(0);
  const {
    actionCreators: { authenticate, optGenerate, otpValidate },
  } = useActionCreators();
  const { track } = useSegment();
  const [segmentUserDetails, setSegmentUserDetails] = useState({});

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(otvcCardSchema),
  });

  const navigate = useNavigate();
  const makeRequest = useRequest();

  const errorHandler = (e: any) => {
    if (e.status === 404) {
      track('Policy Not Found', {
        event_type: 'Automated System Process',
      });
      setInsuredContext({ incorrectPolicyInformation: true });
    } else {
      setInsuredContext({ errorRetrievingPolicy: true });
    }
    updateLoadingContext({ value: false });
    navigate('/');
  };

  const handleOtpGenerate = () => {
    const optGenerateRequest: OtpGenerateRequest = {
      requestorEmail: insuredContext.lenderEmail!,
    };
    setSegmentUserDetails({
      insured_last_name: insuredContext.insuredLastName,
      zip_code: insuredContext.propertyZipCode,
      event_type: 'Automated System Process',
      insured_dob: getYearFromStringDateDDmmYYYY(
        insuredContext?.insuredDateOfBirth
      ),
    });
    makeRequest(() =>
      optGenerate(insuredContext.policyNumber || '', optGenerateRequest)
        .then(() => {
          updateLoadingContext({
            value: false,
          });
          track('Policy Information Requested', segmentUserDetails);
        })
        .catch(errorHandler)
    );
  };

  const handleOtpValidate = (otp: string) => {
    track('Verification Code Entered', {
      event_type: 'User Information Entered',
    });
    updateLoadingContext({
      value: true,
      message: 'Validating your code...',
    });
    const optValidateRequest: OtpValidateRequest = {
      requestorEmail: insuredContext.lenderEmail!,
      otp,
    };
    makeRequest(() =>
      otpValidate(insuredContext.policyNumber || '', optValidateRequest)
        .then((response: any) => {
          setCookie('token', response.accessToken);
          track('Lenderhub Login Succeeded', segmentUserDetails);
          navigate('/details', { replace: true });
          updateLoadingContext({
            value: false,
          });
        })
        .catch((e) => {
          if (e.status === 400) {
            track('Lenderhub Login Failed', {
              ...segmentUserDetails,
              ...{
                reason: `Invalid code entered`,
              },
            });
          } else {
            track('Technical Error Encountered', {
              ...segmentUserDetails,
              ...{
                reason: e.message,
                error_code: e.status,
              },
            });
          }
          setIsInvalidCode(true);
          setNumberOfAttempts((attempts) => attempts + 1);
          updateLoadingContext({
            value: false,
          });
        })
    );
  };

  useEffect(() => {
    if (!insuredContext?.lenderEmail) {
      navigate('/');
    } else {
      updateLoadingContext({
        value: true,
        message: 'We’re gathering details of the policy you’ve requested.',
      });
      const requestBody = policyDetails.buildPolicyRequest(insuredContext);

      makeRequest(() =>
        authenticate(insuredContext.policyNumber || '', requestBody)
          .then((response: any) => {
            setCookie('token', response.accessToken);
            handleOtpGenerate();
          })
          .catch(errorHandler)
      );
    }
  }, []);

  const { ref, ...registerRest } = register('code');

  return insuredContext?.lenderEmail ? (
    <BasicLayout>
      <Container className={styles.body}>
        <div className={styles.otvcCard}>
          <div className={styles.title}>Verification code</div>
          <div className={styles.main}>
            <Container className={styles.details}>
              <Box className={styles.box}>
                <div className={styles.iconShieldContainer}>
                  <IconShield />
                </div>
                {numberOfAttempts < 3 ? (
                  <>
                    <div className={styles.instructions}>
                      Enter the 5-digit code we sent to{' '}
                      {obfuscateEmail(insuredContext.lenderEmail)}. It should
                      arrive in a few minutes.
                    </div>
                    <Box sx={{ width: 328, mx: 'auto', mt: 5 }}>
                      <PatternFormat
                        {...registerRest}
                        customInput={TextField}
                        format={'# # # # #'}
                        mask="_"
                        onValueChange={({ value }) => {
                          setValue('code', value);
                        }}
                        error={isInvalidCode || !!errors.code}
                        placeholder="5-digit code"
                        variant="outlined"
                        helperText={
                          isInvalidCode
                            ? 'Invalid code. Please retry or click Resend now for a new code.'
                            : errors.code?.message || ''
                        }
                        InputProps={{ className: styles.codeInput }}
                      />
                      <Button
                        variant="text"
                        className={styles.resendButton}
                        onClick={() => {
                          setShowResendInfo(true);
                          track('Verification Code Resent', {
                            event_type: 'User Information Entered',
                          });
                          handleOtpGenerate();
                        }}
                      >
                        Resend now
                      </Button>
                    </Box>
                    {showResendInfo && (
                      <div className={styles.resendInfoContainer}>
                        <Alert
                          severity="info"
                          variant="standard"
                          icon={<IconInfo />}
                        >
                          Code has been resent. Please check your email.
                        </Alert>
                      </div>
                    )}
                    <Stack
                      flexDirection="row"
                      justifyContent="flex-end"
                      sx={{ width: 1, mt: 5 }}
                    >
                      <Button
                        variant="contained"
                        disabled={!isValid}
                        onClick={handleSubmit(({ code }) =>
                          handleOtpValidate(code)
                        )}
                      >
                        Continue
                      </Button>
                    </Stack>
                  </>
                ) : (
                  <>
                    <div className={styles.exceededAttemptsInfo}>
                      We’re unable to validate your code. Please try again
                      later.
                    </div>
                    <div className={styles.buttonContainer}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          navigate('/');
                        }}
                      >
                        Return to Home
                      </Button>
                    </div>
                  </>
                )}
              </Box>
            </Container>
          </div>
        </div>
      </Container>
    </BasicLayout>
  ) : (
    <></>
  );
};

export default OtvcCard;
