import { Dialog, DialogTitle, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import Button, { OutlinedButton } from '../styledComponents/Button';

import RemoveButton from '../styledComponents/iconButtons/RemoveButton';

interface ExitConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  handleContinue: () => Promise<void>;
  title: string;
  bodyText: string[];
  leaveText: string;
  stayText: string;
  showRemoveIcon?: boolean;
}

const ExitConfirmationModal = ({
  open,
  onClose,
  handleContinue,
  title,
  bodyText,
  leaveText,
  stayText,
  showRemoveIcon,
}: ExitConfirmationModalProps) => {
  const [continueLoading, setContinueLoading] = useState(false);
  return (
    <Dialog
      aria-labelledby={`${title.split(' ').join('-')}-label`}
      data-testid="exit-confirmation-dialog"
      sx={{ backdropFilter: 'blur(5px)' }}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 4,
          margin: 0,
          px: { mobile: 3, tablet: 4 },
          position: { mobile: 'fixed', tablet: 'relative' },
          width: { mobile: 1, tablet: 480 },
          height: 'auto',
          maxHeight: 1,
        },
      }}
    >
      {showRemoveIcon && (
        <Stack
          direction="row"
          alignItems="flex-end"
          justifyContent="flex-end"
          sx={{
            mt: 2,
          }}
        >
          <RemoveButton
            aria-label="Close Exit Confirmation Modal"
            disableRipple
            data-testid="closeExitConfirmationModal"
            onClick={onClose}
            autoFocus
          />
        </Stack>
      )}
      <Stack direction="column" sx={{ width: 1 }}>
        <DialogTitle
          id={`${title.split(' ').join('-')}-label`}
          sx={{
            fontSize: { mobile: 18, tablet: 28 },
            fontFamily: 'RT Raleway',
            fontWeight: 700,
            textAlign: 'center',
            p: 0,
            lineHeight: { mobile: '32px', tablet: '40px' },
            mt: 3,
          }}
        >
          {title}
        </DialogTitle>
        {bodyText?.map((text, idx) => (
          <Typography
            key={text}
            sx={{
              fontSize: { mobile: 16, tablet: 18 },
              fontFamily: 'Cabin',
              fontWeight: 400,
              lineHeight: '24px',
              mt: { mobile: 2, tablet: 3 },
            }}
          >
            {text}
          </Typography>
        ))}
        <OutlinedButton
          data-testid="exit-confirmation-modal-sign-out-button"
          width={1}
          sx={{
            mt: { mobile: 3, tablet: 5 },
            '&:focus': {
              outline: '2px solid #0B1421',
              outlineOffset: '2px',
            },
          }}
          onClick={onClose}
        >
          {stayText}
        </OutlinedButton>
        <Button
          data-testid="exit-confirmation-modal-continue-button"
          fullWidth
          sx={{
            mt: { mobile: 1, tablet: 2 },
            mb: 5,
            '&:focus': {
              outline: '2px solid #00338D',
              outlineOffset: '2px',
            },
          }}
          onClick={async () => {
            setContinueLoading(true);
            await handleContinue();
          }}
          loading={continueLoading}
        >
          {leaveText}
        </Button>
      </Stack>
    </Dialog>
  );
};

export default ExitConfirmationModal;
