import React from 'react';
import { Stack, Link, Divider, Typography } from '@mui/material';

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer>
        <Stack flexDirection="column" alignItems="center" sx={{ p: 3 }}>
          <Stack
            justifyContent="center"
            alignItems={{ mobile: 'left', tablet: 'center' }}
            direction={{ mobile: 'column', tablet: 'row' }}
            sx={{
              flexWrap: 'wrap',
              mb: { mobile: 3, tablet: 2 },
            }}
          >
            <Link
              href="https://csaa-insurance.aaa.com/content/aaa-ie/b2c/en/misc/terms-of-use.html"
              rel="noopener"
              target="_blank"
              underline="none"
              sx={{
                color: 'primary',
                fontWeight: 700,
                fontSize: { mobile: 14, tablet: 18 },
                fontFamily: 'Cabin',
                whiteSpace: 'nowrap',
              }}
            >
              {'Terms of Use'}
            </Link>
            <Divider
              orientation="vertical"
              sx={{
                height: '1rem',
                mx: { mobile: 0, tablet: 2 },
              }}
            />
            <Link
              href="https://csaa-insurance.aaa.com/content/aaa-ie/b2c/en/misc/privacy-notice.html"
              rel="noopener"
              target="_blank"
              underline="none"
              sx={{
                color: 'primary',
                fontWeight: 700,
                fontSize: { mobile: 14, tablet: 18 },
                fontFamily: 'Cabin',
                whiteSpace: 'nowrap',
              }}
            >
              {'Privacy Policy'}
            </Link>
          </Stack>
          <Stack alignItems="center">
            <Typography
              sx={{
                //color: colors.csaaMid,
                fontSize: { mobile: 10, tablet: 12 },
                fontFamily: 'Cabin',
              }}
            >
              Insurance provided by CSAA Insurance Group, a AAA Insurer
            </Typography>
            <Typography
              sx={{
                //color: colors.csaaMid,
                fontSize: { mobile: 10, tablet: 12 },
                fontFamily: 'Cabin',
              }}
            >
              © {currentYear} CSAA Insurance Group
            </Typography>
          </Stack>
        </Stack>
      </footer>
    </>
  );
}
