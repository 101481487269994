import React from 'react';
import InsuredContextProvider from './components/contexts/InsuredContext';
import LoadingContextProvider from './components/contexts/LoadingContext';
import IdleTimer from './components/IdleTimer';
import { Outlet } from 'react-router-dom';
import RealTimeResponseContextProvider from './components/contexts/RealTimeResponseContext';
import { ThemeProvider } from '@mui/material';
import uiKitTheme from '@aaa-ncnu-ie/ui-kit/dist/styles/defaultTheme';

const Root = () => {
  return (
    <LoadingContextProvider>
      <InsuredContextProvider>
        <RealTimeResponseContextProvider>
          <ThemeProvider theme={uiKitTheme}>
            <React.StrictMode>
              <Outlet />
            </React.StrictMode>
          </ThemeProvider>
          <IdleTimer />
        </RealTimeResponseContextProvider>
      </InsuredContextProvider>
    </LoadingContextProvider>
  );
};

export default Root;
