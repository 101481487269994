import React from 'react';
import logo from '../images/icons/logo.svg';
import Link from '@mui/material/Link';
import { clearSessionAndCookies } from './helpers/cookies';
import { Box, Stack } from '@mui/material';

export default function Header() {
  return (
    <>
      <Stack flexDirection="row" sx={{ ml: 8, py: 5 }}>
        <Box
          component="header"
          sx={{
            mx: 'auto',
            width: 1,
          }}
        >
          <Link href="/" onClick={clearSessionAndCookies}>
            <img src={logo} className="csaa-logo" alt="CSAA Insurance Group" />
          </Link>
        </Box>
      </Stack>
    </>
  );
}
