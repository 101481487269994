import { FormControl, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import React, { useContext, useEffect, useState } from 'react';
import {
  defaultLien,
  Mortgagee,
  MortgageeRequest,
} from '../../api/schema/apiDocuments';
import policyDetails from '../../helpers/policyDetails';
import useSegment from '../../hooks/useSegment';
import Modal from '../common/Modal';
import UsStatesSelector from '../UsStatesSelector';
import '../../styles/modal/RealTimeMortChangeModal.scss';
import { useForm } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { InsuredContext } from '../contexts/InsuredContext';
import { useNavigate } from 'react-router-dom';
import { useActionCreators } from '../../api/actions';
import useRequest from '../../api/makeRequest';
import { RealTimeResponseContext } from '../contexts/RealTimeResponseContext';
import { yupResolver } from '@hookform/resolvers/yup';
import mortChangeModalSchema from '../../yup-schemas/MortChangeModal';
import { LoadingContext } from '../contexts/LoadingContext';

const RealTimeMortChangeModal = (props: {
  currentLien: Mortgagee;
  open: boolean;
  policyInsured: string;
  onClose: () => void;
  originalLienSize: number;
  lienList: Array<Mortgagee>;
  setLienList: (lienList: Array<Mortgagee>) => void;
}) => {
  const {
    currentLien,
    open,
    onClose,
    policyInsured,
    originalLienSize,
    lienList,
    setLienList,
  } = props;
  const [lien, setLien] = useState(defaultLien as Mortgagee);
  const [, updateLoadingContext] = useContext(LoadingContext);
  const { track } = useSegment();
  const isUpdateLienMode = Object.keys(currentLien).length > 0;
  const [insuredContext, setInsuredContext] = useContext(InsuredContext);
  const [, updateRealTimeContext] = useContext(RealTimeResponseContext);
  const navigate = useNavigate();

  const {
    actionCreators: { updateMortgagee },
  } = useActionCreators();
  const makeRequest = useRequest();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(mortChangeModalSchema),
  });

  const closeModal = () => {
    onClose();
    reset();
  };

  useEffect(() => {
    if (open && isUpdateLienMode) {
      setLien(currentLien);
      setValue('legalMortgageeName', currentLien.legalMortgageeName);
      setValue('mortgageeAddress1', currentLien.mortgageeAddress1);
      setValue('mortgageeCity', currentLien.mortgageeCity);
      setValue('mortgageeState', currentLien.mortgageeState);
      setValue('mortgageePostalCode', currentLien.mortgageePostalCode);
      setValue('loanNumber', currentLien.loanNumber);
      updateRealTimeContext({
        zendeskRequest: false,
        realTimeRequest: false,
      });
      setValue('mortgageeState', currentLien.mortgageeState);
      setValue('mortgageePostalCode', currentLien.mortgageePostalCode);
      setValue('loanNumber', currentLien.loanNumber);
    } else {
      setLien(defaultLien);
    }
  }, [open]);

  const saveEditedLien = () => {
    const eventType = 'User Information Entered';
    if (isUpdateLienMode) {
      track('Mortgagee Details Changed', {
        eventType,
      });
    } else {
      track('Mortgagee Information Added', {
        eventType,
        mortgagee_name: lien.legalMortgageeName,
        address: lien.mortgageeAddress1,
        city: lien.mortgageeCity,
        state: lien.mortgageeState,
        zip_code: lien.mortgageePostalCode,
        loan_number: lien.loanNumber,
      });
    }
    const nextLienPosition =
      lienList.filter((lien) => lien.requestType !== 'delete').length + 1;
    let liens: Array<Mortgagee> = [];

    if (
      lien.lienPosition ||
      (!lien.lienPosition && nextLienPosition <= originalLienSize)
    ) {
      //has Position Or Next Position Is Less Than Original Size
      liens = policyDetails.markExistingLienToBeEdited(
        lienList,
        lien.lienPosition ? lien.lienPosition : nextLienPosition,
        lien
      );
    }
    if (!lien.lienPosition && nextLienPosition > originalLienSize) {
      // new Lien and next position is after existing liens
      liens = policyDetails.addNewLien(lienList, lien, nextLienPosition);
    }
    const lienPostionZero = (lien?.lienPosition ?? 0) - 1;
    const mortRequest: MortgageeRequest = {
      mortgagee: {
        insuredFullName: policyInsured,
        lienPosition: lienPostionZero,
        oldValue: {
          mortgageeName: currentLien.legalMortgageeName,
          addressLine1: currentLien.mortgageeAddress1,
          addressLine2: currentLien?.mortgageeAddress2,
          city: currentLien.mortgageeCity,
          postalCode: currentLien.mortgageePostalCode,
          state: currentLien.mortgageeState,
          loanNumber: currentLien.loanNumber,
        },
        newValue: {
          mortgageeName: lien.legalMortgageeName,
          addressLine1: lien.mortgageeAddress1,
          addressLine2: lien?.mortgageeAddress2,
          city: lien.mortgageeCity,
          postalCode: lien.mortgageePostalCode,
          state: lien.mortgageeState,
          loanNumber: lien.loanNumber,
        },
      },
    };

    saveMortgagee(mortRequest);
    setLienList(liens);
    closeModal();
  };

  const errorHandler = (e: any) => {
    if (e.status === 504 || e.status === 403) {
      track('Policy Not Found', {
        event_type: 'Automated System Process',
      });
      setInsuredContext({ errorRetrievingPolicy: true });
    }
    updateLoadingContext({ value: false });
    navigate('/details');
  };

  const saveMortgagee = (request: MortgageeRequest) => {
    updateLoadingContext({
      value: true,
      message: 'Validating update...',
    });
    makeRequest(() =>
      updateMortgagee(insuredContext.policyNumber || '', request)
        .then((response: any) => {
          if (response.statusCode === 202) {
            updateRealTimeContext({ zendeskRequest: true });
            updateLoadingContext({
              value: false,
            });
          }
          if (response.statusCode === 200) {
            updateRealTimeContext({ realTimeRequest: true });
            updateLoadingContext({
              value: false,
            });
          }
        })
        .catch(errorHandler)
    );
  };

  const handleMortChange = ({
    name,
    value,
  }: HTMLInputElement | { name: string; value: string }) => {
    setLien({ ...lien, ...{ [name]: value } });
  };
  return (
    <Modal
      fullWidth={false}
      title={isUpdateLienMode ? 'Mortgagee update' : 'Add mortgagee'}
      open={open}
      className="mort-modal"
      modalName="mort-modal"
      onClose={closeModal}
    >
      <Box className="mort-modal-background">
        <DialogContent>
          <DialogContentText
            className="PolicyDetailsCard-details-message"
            style={{ color: '#0B1421', paddingBottom: '25px' }}
          >
            Please provide the updated mortgagee information.
          </DialogContentText>
          <TextField
            {...register('legalMortgageeName')}
            error={!!errors.legalMortgageeName}
            autoFocus
            margin="dense"
            id="updatedMortName"
            label="Mortgagee Name including ISAOA or ATIMA if applicable"
            sx={{
              width: '100%',
            }}
            type="text"
            inputProps={{ maxLength: 50 }}
            variant="filled"
            onChange={(event) => handleMortChange(event.target)}
            color="secondary"
            value={lien?.legalMortgageeName}
            helperText={`${errors.legalMortgageeName?.message || ''}`}
            required
          />
          <TextField
            {...register('mortgageeAddress1')}
            error={!!errors.mortgageeAddress1}
            margin="dense"
            label="Address"
            type="text"
            sx={{ width: '100%' }}
            name="mortgageeAddress1"
            inputProps={{ maxLength: 50 }}
            variant="filled"
            onChange={(event) => handleMortChange(event.target)}
            color="secondary"
            value={lien?.mortgageeAddress1}
            helperText={`${errors.mortgageeAddress1?.message || ''}`}
            required
          />
          <TextField
            {...register('mortgageeCity')}
            error={!!errors.mortgageeCity}
            margin="dense"
            id="updatedMortCity"
            label="City"
            type="text"
            sx={{ width: '100%' }}
            // fullWidth
            name="mortgageeCity"
            inputProps={{ maxLength: 30 }}
            variant="filled"
            onChange={(event) => handleMortChange(event.target)}
            color="secondary"
            value={lien?.mortgageeCity}
            helperText={`${errors.mortgageeCity?.message || ''}`}
            required
          />
          <FormControl
            variant="filled"
            color="secondary"
            sx={{
              m: 1,
              minWidth: 120,
              width: '100%',
              marginLeft: 0,
              marginRight: 0,
            }}
          >
            <UsStatesSelector
              initialValue={lien?.mortgageeState}
              onChangeAutoComplete={(value = '') => {
                setValue('mortgageeState', value);
                handleMortChange({ name: 'mortgageeState', value });
              }}
              onBlurAutoComplete={() => {
                trigger('mortgageeState');
              }}
              error={!!errors.mortgageeState}
              helperText={`${errors.mortgageeState?.message || ''}`}
              color="secondary"
            />
          </FormControl>
          <PatternFormat
            margin="dense"
            id="updatedMortZip"
            value={lien?.mortgageePostalCode}
            customInput={TextField}
            format={'#####-####'}
            sx={{ width: '100%' }}
            mask="_"
            onValueChange={({ value }) => {
              setValue('mortgageePostalCode', value);
              handleMortChange({ name: 'mortgageePostalCode', value });
            }}
            onBlur={() => {
              trigger('mortgageePostalCode');
            }}
            error={!!errors.mortgageePostalCode}
            label="Zip code"
            variant="filled"
            color="secondary"
            helperText={`${errors.mortgageePostalCode?.message || ''}`}
            required
          />

          <TextField
            {...register('loanNumber')}
            error={!!errors.loanNumber}
            margin="dense"
            id="updatedMortLoan"
            label="Loan number"
            sx={{ width: '100%' }}
            type="text"
            inputProps={{ maxLength: 30 }}
            variant="filled"
            name="loanNumber"
            onChange={(event) => handleMortChange(event.target)}
            color="secondary"
            value={lien?.loanNumber}
            helperText={`${errors.loanNumber?.message || ''}`}
            required
          />
        </DialogContent>
        <DialogActions>
          <Box justifyContent="center" style={{ width: '95%' }}>
            <Button
              style={{ width: '95%' }}
              onClick={handleSubmit(saveEditedLien)}
              disabled={!mortChangeModalSchema.isValidSync(lien)}
            >
              {isUpdateLienMode ? 'Update' : 'Done'}
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Modal>
  );
};

export default RealTimeMortChangeModal;
